import { render, staticRenderFns } from "./personalManage.vue?vue&type=template&id=87dc6fa2&scoped=true&"
import script from "./personalManage.vue?vue&type=script&lang=js&"
export * from "./personalManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87dc6fa2",
  null
  
)

export default component.exports