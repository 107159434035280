<template>
  <div class="app_con">
    <app-list
      :rules="rules"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :labelWidth="labelWidth"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="请输入内容"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="请输入身份证号"
          v-model="searchQuery.idCard"
          class="searchInput"
        >
          <template slot="prepend">身份证</template>
        </el-input>
        <el-input
          clearable
          placeholder="请输入手机号"
          v-model="searchQuery.phone"
          class="searchInput"
        >
          <template slot="prepend">手机号</template>
        </el-input>
        <el-input
          clearable
          placeholder="请输入摊位编号"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位编号</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("姓名不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("电话不能为空"));
              } else if(!this.$test.mobile(value)){
                 callback(new Error("电话格式不对"));
              } else if(value.length!==11){
                 callback(new Error("请输入11位电话号码"));
              }else{
                 callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        idCard: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("身份证号不能为空"));
              } else if(!this.$test.idCard(value)) {
                 callback(new Error("身份证格式有误"));
              }else{
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        licenseExpireDate: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("日期不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        licenseNo: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("执照编号不能为空"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        avatarPath: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请上传头像"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        licensePath: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请上传营业执照"));
              } else {
                callback();
              }
            },
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        
      },
      remote: api.common,
      apiName: "/merchant/personnel",
      searchVal: "",
      jobList: [],
      searchQuery: { name: "" },
      labelWidth: "120px",
      props: [
        {
          label: "商户姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "性别",
          prop: "sex",
          align: "center",
          formatter: function (row) {
            const statusMap = { 0: "未知", 1: "男", 2: "女" };
            return statusMap[row.sex];
          },
        },

        {
          label: "身份证号",
          prop: "idCard",
          align: "center",
        },
        {
          label: "手机号",
          prop: "phone",
          align: "center",
        },
        {
          label: "营业执照编号",
          prop: "licenseNo",
          align: "center",
          width: 220,
        },
        {
          label: "执照到期",
          prop: "licenseExpireDate",
          align: "center",
        },
        {
          label: "摊位编号",
          prop: "stallNo",
          align: "center",
        },
        {
          label: "经营类型名称",
          prop: "businessType",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "姓名",
          prop: "name",
          type: "input",
        },

        {
          label: "电话",
          prop: "phone",
          type: "input",
        },
        {
          label: "身份证号",
          prop: "idCard",
          type: "input",
        },
        {
          label: "执照到期",
          prop: "licenseExpireDate",
          type: "date",
        },
        {
          label: "执照编号",
          prop: "licenseNo",
          type: "input",
        },
        {
          label: "经营类别",
          prop: "businessType",
          type: "select",
          selectData: [],
        },

        {
          label: "上传头像",
          prop: "avatarPath",
          type: "upload",
        },
        {
          label: "营业执照",
          prop: "licensePath",
          type: "upload",
        },
        {
          label: "健康证",
          prop: "healthProvePath",
          type: "upload",
        },

        {
          label: "流通许可证",
          prop: "circulateProvePath",
          type: "upload",
        },
        {
          label: "身份证正面",
          prop: "idCardFrontPath",
          type: "upload",
        },
        {
          label: "身份证反面",
          prop: "idCardContraryPath",
          type: "upload",
        },
        {
          label: "支付宝二维码",
          prop: "alipayPayPath",
          type: "upload",
        },
        {
          label: "微信二维码",
          prop: "weixinPayPath",
          type: "upload",
        },

        // {
        //   label: "聚合支付",
        //   prop: "bankPayQrCodePath",
        //   type: "upload",
        // },
        {
          label: "商品溯源",
          prop: "traceabilityPath",
          type: "upload",
        },
      ],
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    async getTypeList() {
      await api.common.getBusinessTypeList().then((res) => {
        let arr = res.data;
        console.log(this.formProps[3]);
        arr.forEach((item) => {
          this.formProps[5].selectData.push({
            value: item.id,
            label: item.name,
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
